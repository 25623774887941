<template>
  <div class="reply-detail-container">
    <div class="message" v-for="(item, i) in discuss" :key="i">
      <div class="head">
        <img
          @click="goAuther(item)"
          class="photo"
          v-lazy="$global.imgUrl + item.createUserHeadImg"
          alt=""
        />
        <span class="name" @click="goAuther(item)">{{
          item.createUserName
        }}</span>
        <svg
          id="图层_1"
          data-name="图层 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 170 170"
        >
          <title>icon-sch</title>
          <circle class="cls-1" cx="119.88" cy="119.88" r="48.12" />
          <path
            class="cls-1"
            d="M67.19,129c3.4,28.13,31.16,38.44,31.16,38.44-9.18,1.34-18.71.52-29.89.52-35.8,0-64.81-1.86-64.81-37.66A64.78,64.78,0,0,1,35,74.8,51.26,51.26,0,0,0,68.46,87.88a31.33,31.33,0,0,0,9.17-1.17S63.79,100.9,67.19,129Z"
          />
          <circle class="cls-1" cx="68.46" cy="41.67" r="39.42" />
          <path
            class="cls-2"
            d="M89.16,122l21.28,19.13a9.81,9.81,0,0,0,14.24-1.2l27.73-35"
          />
        </svg>
        <span class="date">{{ item.createDate | dayFormate }}</span>
      </div>
      <div class="content">
        {{ item.content }}
      </div>
      <div class="achevement" v-if="item.files && item.files[2]">
        <div
          class="title"
          @click="goDetails(v)"
          v-for="(v, i) in item.files[2]"
          :key="i"
        >
          <!-- <span class="column">[{{ v.typeName }}]</span> -->
          {{ v.resultName }}
        </div>
      </div>
      <div class="picture" v-if="item.files && item.files[1]">
        <img
          :src="$global.imgUrl + v.accessPath"
          alt=""
          v-for="(v, i) in item.files[1]"
          :key="i"
        />
      </div>
    </div>
    <Paging
      :total="total"
      :curPage="pageNo"
      :limit="10"
      @changePager="handlePager"
    ></Paging>
  </div>
</template>

<script>
import Paging from "@/components/paging/index.vue";
export default {
  components: {
    Paging,
  },
  data() {
    return {
      pageNo: 1,
      total: 0,
      discuss: [],
    };
  },
  created() {
    this.discussReplyList({
      id: this.$route.query.id,
      pageNo: 1,
      pageSize: 10,
    });
  },
  methods: {
    goAuther(v) {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: { id: v.createUserId },
      });
      window.open(url.href, "_blank");
    },
    goDetails(item) {
      let url;
      if (item.resultType === 0) {
        if (item.type === 0) {
          url = this.$router.resolve({
            path: "paperDetail",
            query: {
              articleType: item.resultType,
              articleId: item.resultId,
            },
          });
        } else if (item.type === 1) {
          url = this.$router.resolve({
            path: "conferencepapers",
            query: {
              articleType: item.resultType,
              articleId: item.resultId,
            },
          });
        } else if (item.type === 2) {
          url = this.$router.resolve({
            path: "conferenceppt",
            query: {
              articleType: item.resultType,
              articleId: item.resultId,
            },
          });
        } else if (item.type === 4) {
          url = this.$router.resolve({
            path: "conferenceother",
            query: {
              articleType: item.resultType,
              articleId: item.resultId,
            },
          });
        }
      } else if (item.resultType === 1) {
        url = this.$router.resolve({
          path: "patentdetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      } else if (item.resultType === 2) {
        url = this.$router.resolve({
          path: "monographdetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      } else if (item.resultType === 3) {
        url = this.$router.resolve({
          path: "reportdetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      } else if (item.resultType === 4) {
        url = this.$router.resolve({
          path: "standarddetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      } else if (item.resultType === 5 && item.type === 0) {
        url = this.$router.resolve({
          path: "informationdetail",
          query: {
            articleType: item.resultType,
            articleId: item.resultId,
          },
        });
      }
      window.open(url.href, "_blank");
    },
    handlePager(page) {
      this.discussReplyList({
        id: this.$route.query.id,
        pageNo: page,
        pageSize: 10,
      });
    },
    async discussReplyList(data) {
      const resp = await this.$apis.circleServe.discussReplyList(data);
      this.discuss = resp.data.list;
      this.total = +resp.data.total;
      this.pageNo = +resp.data.pageNum;
    },
  },
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #00a4ff;
}
.cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 7px;
}
.message {
  padding-bottom: 29px;
  border-bottom: 1px dashed #999999;
  margin-bottom: 20px;
  .head {
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
    }
    .name {
      cursor: pointer;
    }
  }
  .content {
    margin-top: 9px;
    font-size: 14px;
    color: #333333;
    word-break: break-all;
  }
  .achevement {
    margin-top: 20px;
    .title {
      width: 651px;
      height: 20px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      text-decoration: underline;
      color: #00a4ff;
      cursor: pointer;
    }
  }
  .picture {
    margin-top: 12px;
    img {
      width: 199px;
      height: 117px;
      margin-right: 10px;
    }
  }
  .photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    vertical-align: middle;
  }
  .name {
    font-size: 18px;
    color: #444444;
    vertical-align: middle;
    margin: 0 10px;
  }
  svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
  .date {
    margin-left: 20px;
  }
  .icon {
    margin-top: 5px;
    display: flex;
    li {
      width: 17px;
      height: 17px;
      margin: 0 3px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
